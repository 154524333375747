import React from "react"
import styled from "styled-components"

import { Seo, Layout, OtherPageWrapper, MainWrapper } from "components"

const LocationsSection = styled.div`
  background-color: var(--whiteChalk);
`

const Heading = styled.h1`
  text-align: center;
  font-size: 30px;
  padding-top: 70px;
`

const AboutContent = styled.div`
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

const MapBox = styled.div`
  > h1 {
    font-family: "Oswald", sans-serif;
    color: var(--primaryColor);
    padding: 30px 0;
    font-size: 20px;
    text-align: center;
  }

  .iframe {
    display: flex;
    justify-content: center;

    > iframe {
      border: 0;
      border-radius: 20px;
    }
  }
`

const LocationsPage = () => {
  const locations = [
    {
      name: "Wiley Canyon Rd & Calgrove Blvd",
      point:
        "!1m18!1m12!1m3!1d52695.04467202129!2d-118.62338662147522!3d34.36472031098842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c286991dd4aca7%3A0xbae874e29af7469c!2sWiley%20Canyon%20Rd%20%26%20Calgrove%20Blvd!5e0!3m2!1sen!2sus!4v1651247936696!5m2!1sen!2sus",
    },
    {
      name: "McBean Regional Transit Center",
      point:
        "!1m18!1m12!1m3!1d52663.97203238833!2d-118.63237738609314!3d34.414096990174414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2873ed177b84d%3A0x5a541f810f4e83cb!2sMcBean%20Regional%20Transit%20Center!5e0!3m2!1sen!2sus!4v1651248176508!5m2!1sen!2sus",
    },
    {
      name: "The Old Rd & Sagecrest Cir",
      point:
        "!1m18!1m12!1m3!1d7321.081555475676!2d-118.5721041334173!3d34.378690420451605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c286c632f6bf11%3A0xc2bc3afe1664734d!2sThe%20Old%20Rd%20%26%20Sagecrest%20Cir!5e0!3m2!1sen!2sus!4v1651252651166!5m2!1sen!2sus",
    },
    {
      name: "The Old Rd & Silver Aspen Wy",
      point:
        "!1m18!1m12!1m3!1d13167.491027612734!2d-118.57173321571861!3d34.40457997966877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c28734ce15ebd7%3A0x4f944ef5ecf4c29c!2sThe%20Old%20Rd%20%26%20Silver%20Aspen%20Wy!5e0!3m2!1sen!2sus!4v1651892001722!5m2!1sen!2sus",
    },
    {
      name: "The Old Rd & Steinbeck Ave",
      point:
        "!1m18!1m12!1m3!1d14460.13640637354!2d-118.58052556683583!3d34.39874508686756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c286d6a4ce5b19%3A0x97825a331429fea2!2sThe%20Old%20Rd%20%26%20Steinbeck%20Ave!5e0!3m2!1sen!2sus!4v1651892154793!5m2!1sen!2sus",
    },
    {
      name: "Bouquet Canyon Rd & Plum Canyon Rd",
      point:
        "!1m18!1m12!1m3!1d52651.0040275646!2d-118.52830093206312!3d34.43468572781299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c287f9da1b910d%3A0x8df0ac6974fa948a!2sBouquet%20Canyon%20Rd%20%26%20Plum%20Canyon%20Rd!5e0!3m2!1sen!2sus!4v1653591041954!5m2!1sen!2sus",
    },
    {
      name: "Hazel St",
      point:
        "!1m18!1m12!1m3!1d52631.52896152382!2d-118.60031962394714!3d34.46558518889988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c27d752a5fc627%3A0xcef751dce0cc570!2sHazel%20St!5e0!3m2!1sen!2sus!4v1653591135078!5m2!1sen!2sus",
    },
    {
      name: "Rye Canyon Rd & Ave Scott",
      point:
        "!1m18!1m12!1m3!1d52649.0997447453!2d-118.62612832938942!3d34.43770816623736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c27dfe6573bbf9%3A0x79db2afc81ff142a!2sRye%20Canyon%20Rd%20%26%20Ave%20Scott!5e0!3m2!1sen!2sus!4v1653591179104!5m2!1sen!2sus",
    },
  ]

  return (
    <Layout>
      <Seo title="Public Transportation to Neighbarista" />
      <OtherPageWrapper title="How to Get To Neighbarista" />
      <LocationsSection>
        <MainWrapper>
          <Heading>Public Transportation to Neighbarista</Heading>
          <AboutContent>
            {locations.map((item, index) => (
              <MapBox key={index}>
                <div className="iframe">
                  <iframe
                    title={item.name}
                    src={`https://www.google.com/maps/embed?pb=${item.point}`}
                    width="300"
                    height="368"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>

                <h1>{item.name}</h1>
              </MapBox>
            ))}
          </AboutContent>
        </MainWrapper>
      </LocationsSection>
    </Layout>
  )
}

export default LocationsPage
